@import '~@falla/less/index.less';

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

a {
  color: #1677ff;
  transition: all 0.3s ease-in-out;
  margin: 0 5px;

  &:hover,
  &:active {
    opacity: 0.8;
    text-decoration: underline;
  }
}
