/**
 * index
 *
 * @author fukui
 */
@import './mixins/index.less';
@import './base/index.less';
//@import './font/index';
@import './reset/index.less';
@import './animation/index.less';

@ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
@all-transition-base: all 0.3s @ease-in-out-cubic;
@all-transition-active: all 0.1s linear;
@theme-common-01: #417ff9;
@theme-common-02: #608dfa;
@theme-common-03: #1f72f1;
@primary-color: #8787ff;
@yigo-primary-color: #ff00a1;
@arhbo-primary-color: #58be69;
@arhbo-primary-gradient-color: linear-gradient(90deg, #58be69 0%, #5adf70 100%);
@joymi-primary-color: #8787ff;
@joymi-primary-gradient-color: linear-gradient(270deg, #5a62ff 0%, #a670ff 55%, #e770fe 100%);
@toofun-primary-color: #0a6ffe;
@toofun-primary-gradient-color: linear-gradient(90deg, #05dfff 0%, #4388ff 100%);

html[lang='ar'] {
  .ar-reverse-image {
    transform: rotateY(180deg);
  }
}
